import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

const WinnerDetail = () => {
    const [winner, setWinner] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        fetchWinner();
    }, []);

    const fetchWinner = async () => {
        const response = await axios.get(`/api/hang-tower-winners/${id}`);
        setWinner(response.data);
    };

    if (!winner) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{winner.name}</h1>
            <p>Reward: {winner.reward}</p>
            <p>Draw Date: {winner.draw_date}</p>
            <p>Location: {winner.location}</p>
            {winner.image && <img src={`storage/${winner.image}`} alt={winner.name} width="100" />}
            <Link to="/winners">Back to Winners List</Link>
        </div>
    );
};

export default WinnerDetail;
