import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../Services/UserContext.js';

const PrivateRoute = ({ element: Component, ...rest }) => {
    const { userData } = useUser()
    const location = useLocation();


    if (userData?.token==="") {
        return <Navigate to="/dashboard/login" state={{ from: location }} />;
    }

    if (userData?.role === 'admin' || userData?.role === 'editor') {
        return <Component />;
    } else if (userData?.role === 'user') {
        return <Navigate to="/" />;
    } else {
        return <Navigate to="/dashboard/products" state={{ from: location }} />;
    }
};

export default PrivateRoute;
