

import React, { useEffect } from 'react';
import { Link , Navigate } from 'react-router-dom';
import { useUser } from '../../../../Services/UserContext';


const CartIcon = () => {

    const { cartQuantity, getCartQuantity } = useUser();

    useEffect(() => {
        getCartQuantity(); // Fetch cart quantity when component mounts
    }, [getCartQuantity]);
   

    return (
        <>
       
        <Link className="cart-icon position-relative d-inline-flex align-items-center" to="/">
            <i className="bi bi-cart3 text-white" style={{ fontSize: '1.5rem', color: '#000' }}></i>
            {cartQuantity > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {cartQuantity}
                    <span className="visually-hidden">items in cart</span>
                </span>
            )}
        </Link>
        </>
    );
};

export default CartIcon;
