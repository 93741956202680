import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../../Services/UserContext';
import { BASE_URL } from '../../Services';

import Sidebar from '../sidebar/Sidebar'; 
import Loader from '../../UserSide/Components/LoaderComponent'; 
import './AddBanner.css'; 

function AddBanner() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        description: '',
        product_link: '',
        platform: 'desktop',
        image: null,
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const { userData } = useUser();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        data.append('description', formData.description);
        data.append('product_link', formData.product_link);
        data.append('platform', formData.platform);
        data.append('image', formData.image);

        try {
            await axios.post(`${BASE_URL}/banners`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            toast.success('Banner added successfully!'); 
            navigate('/dashboard/banners');
        } catch (error) {
            toast.error('Error adding banner: ' + (error.response?.data?.message || error.message)); 
            console.error('Error adding banner:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="container-fluid mt-4">
                        <div className="p-4 bg-light rounded shadow-sm">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader /> 
                                </div>
                            ) : (
                                <div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h1 className="mb-4 fs-4 fw-bold text-dark">Add New Banner</h1>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Product Link</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="product_link"
                                                value={formData.product_link}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Platform</label>
                                            <select
                                                className="form-select"
                                                name="platform"
                                                value={formData.platform}
                                                onChange={handleChange}
                                            >
                                                <option value="desktop">Desktop</option>
                                                <option value="mobile">Mobile</option>
                                                <option value="mobilead">Mobile Ads</option>
                                                <option value="both">Both</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Image</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="image"
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary shadow-sm bi bi-plus">
                                            Add Banner
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default AddBanner;
