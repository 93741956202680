import React, { useEffect } from "react";
import HomeBanner from "./Components/HomeBannerComponent";
import RecentLuckyDraws from "./Components/VideosSectionComponent";
import ProductsListComponent from "./Components/ProductsListComponent";

import '../../css/App.css';
import InsightSection from "./Components/InsightSectionComponent";
import Footer from "../../Components/FooterCompnent";
import HangWinnerScreen from "./Components/HangWinnerScreen";
import PromoCodeModal from "../../Components/PromoCodeComponent";
import { useUser } from "../../../Services/UserContext";
import jsPDF from "jspdf";

function App() {
  const {userData,getUserByID} = useUser()
  useEffect(() => {
   
    getUserByID()
  },[])
  return (
    <div className="App">
      <HomeBanner />
      <PromoCodeModal />
      <RecentLuckyDraws />
      <InsightSection />
      <ProductsListComponent />
      <HangWinnerScreen />
      <Footer />
    </div>
  );
}

export default App;
