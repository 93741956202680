import React from 'react'
import Header from '../../Components/HeaderComponent';
import ProductsListComponent from '../HomeScreen/Components/ProductsListComponent';
import Footer from '../../Components/FooterCompnent';
 
const Prizes = () => {
  return (
    <div className="App">
        <Header/>
        <div style={{height:"15vh "}} className='mt-5'/>
        <div className='p-2'>
        <ProductsListComponent/>
        </div>
        <Footer/>
    </div>
  )
}

export default Prizes;