

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent';
import "../../css/Styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_URL } from '../../../Services';

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setBlog(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog details:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }

  const formatDescription = (text) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const formatted = [];

    for (let i = 0; i < lines.length; i += 4) {
      const heading = lines.slice(i, i + 4).join(' ').substring(0, 50); 
      formatted.push({ type: 'heading', text: heading });
      
     
      const description = lines.slice(i + 4, i + 8).join(' ');
      if (description.trim() !== '') {
        formatted.push({ type: 'text', text: description });
      }
    }

    return formatted;
  };

  const conclusion = "Conclusion: This is the summary of the blog post. It encapsulates the main points and provides a final thought.";

  const formattedDescription = formatDescription(blog.description);

  return (
    <div className='App'>
      <Header />
      <section className="blog-details-header inner-page py-5 mt-5">
        <div className="container-fluid col-lg-10  mt-5 p-0">
        
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="card glow" style={{ borderRadius: "15px" }}>
                <img 
                  src={blog.blog_image} 
                  alt={blog.heading} 
                  className="card-img-top" 
                  style={{ height: "auto", maxHeight: "500px", objectFit: "cover", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} 
                />
          <h1  className="mb-4 text-md-center text-start ms-3 mt-3 blog-heading">{blog.heading}</h1>
        
                <div className="card-body p-4 text-start">
                  {formattedDescription.map((item, index) => (
                    <div key={index} className="mb-4">
                      {item.type === 'heading' ? (
                        <h4 className="font-weight-bold">{item.text}</h4>
                      ) : (
                        <p>{item.text}</p>
                      )}
                    </div>
                  ))}
                  <div className="mt-4">
                    <h4 className="font-weight-bold">Conclusion</h4>
                    <p>{conclusion}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogDetails;
