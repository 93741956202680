


import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate } from "react-router-dom";

const TimerApp = ({ onComplete }) => {
  const [currentColor, setCurrentColor] = useState("#04B431");
  const [remainingTime, setRemainingTime] = useState(120);
  const navigate = useNavigate();

  useEffect(() => {
    const currentTime = Date.now();
    const startTime = currentTime; 

    localStorage.setItem("timerStartTime", startTime); 

    const interval = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000); 
      const updatedTimeLeft = Math.max(120 - elapsed, 0); 
      setRemainingTime(updatedTimeLeft);

      if (updatedTimeLeft <= 0) {
        clearInterval(interval);
        onComplete(); 
        localStorage.removeItem("timerStartTime"); 
        navigate("/"); 
      }
    }, 1000); 

    
    return () => clearInterval(interval);
  }, [onComplete, navigate]); 

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div style={styles.container}>
      <img
        style={{ ...styles.image, filter: `brightness(0) saturate(100%) sepia(1) hue-rotate(${currentColor})` }}
        src={require("../../../images/clock_img_amir.jpeg")}
        alt="Clock"
      />
      <CountdownCircleTimer
        isPlaying
        duration={120}
        initialRemainingTime={remainingTime}
        size={80}
        strokeWidth={5}
        colors={["#04B431", "#F7B801", "#A30000", "#880808"]}
        colorsTime={[90, 60, 30, 0]}
      >
        {() => (
          <div style={styles.timerTextContainer}>
            <span style={{ ...styles.timerText, color: currentColor }}>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "40px",
  },
  image: {
    width: "62px",
    height: "20px",
    marginBottom: "-9px",
  },
  timerTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  timerText: {
    fontSize: "28px",
    fontWeight: "600",
    marginTop: "5px",
  },
  timerSubText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#097DC4",
  },
};

export default TimerApp;
