
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';

function ProductsList() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productToDelete, setProductToDelete] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(''); 
    const { userData } = useUser();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });
                if (response.data.status === 200) {
                    setProducts(response.data.products);
                } else {
                    console.error('Failed to fetch products');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchProducts();
    }, [userData]);

    useEffect(() => {
        setFilteredProducts(
            products.filter(product =>
                selectedProduct ? product.name === selectedProduct : true
            )
        );
    }, [selectedProduct, products]);

    const handleDelete = async () => {
        try {
            await axios.delete(`${BASE_URL}/products/${productToDelete}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setProducts(products.filter(product => product.id !== productToDelete));
            setShowDeleteModal(false);
            toast.success('Product deleted successfully!');
        } catch (error) {
            console.error('Error deleting product:', error.response ? error.response.data : error.message);
            toast.error('Failed to delete product.');
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Products List</h1>
                            <Link to="/dashboard/products/create" className="btn btn-primary shadow-sm">
                                <i className="bi bi-plus-circle me-2"></i>Add Product
                            </Link>
                        </div>

                        <select
                            className="form-control mb-4"
                            value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                            <option value="">All Products</option>
                            {Array.from(new Set(products.map(product => product.name))).map(name => (
                                <option key={name} value={name}>{name}</option>
                            ))}
                        </select>

                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{fontSize: '14px'}}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Sr.</th>
                                            <th>ID</th>
                                            <th className="col-2">Name</th>
                                            <th className="col-2 text-center">Image</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Description</th>
                                            <th>Reward</th>
                                            <th className="col-md-1 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredProducts.length ? (
                                            filteredProducts.map(product => (
                                                <tr key={product.id}>
                                                    <td className='align-middle'>{filteredProducts.indexOf(product) + 1}</td>
                                                    <td className='align-middle'>{product.id}</td>
                                                    <td className='align-middle'>{product.name}</td>
                                                    <td className='text-center p-1'>
                                                        {product.image && (
                                                            <img
                                                                src={product.image}
                                                                alt={product.name}
                                                                className="img-fluid"
                                                                style={{ width: '100%', height: '100px', borderRadius: '10px' }} 
                                                            />
                                                        )}
                                                    </td>
                                                    <td className='align-middle text-center'>{product.price}</td>
                                                    <td className='align-middle text-center'>{product.quantity}</td>
                                                    <td className="text-truncate align-middle" style={{ maxWidth: '200px' }}>{product.description}</td>
                                                    <td className="text-truncate align-middle" style={{ maxWidth: '200px' }}>{product.reward ? product.reward.name : 'N/A'}</td>
                                                    <td className='text-center align-middle'>
                                                        <div className='d-flex gap-2 g-0 justify-content-center'>
                                                            <Link to={`/dashboard/products/${product.id}`} className="btn btn-info btn-sm"><i className="bi bi-eye"></i></Link>
                                                            <Link to={`/dashboard/products/${product.id}/edit`} className="btn btn-primary btn-sm"><i className="bi bi-pencil"></i></Link>
                                                            <button className="btn btn-danger btn-sm" onClick={() => { setProductToDelete(product.id); setShowDeleteModal(true); }}><i className="bi bi-trash"></i></button>
                                                        </div> 
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center">No products found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showDeleteModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this product?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
}

export default ProductsList;
