import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

import Sidebar from '../sidebar/Sidebar'; 
import Loader from '../../UserSide/Components/LoaderComponent'; 

function EditBanner() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [banner, setBanner] = useState(null);
    const [formData, setFormData] = useState({
        description: '',
        product_link: '',
        platform: 'desktop',
        image: null,
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
    const [loading, setLoading] = useState(true); 
    const { userData } = useUser();

    useEffect(() => {
        const fetchBanner = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`${BASE_URL}/banners/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                setBanner(response.data);
                setFormData({
                    description: response.data.description,
                    product_link: response.data.product_link,
                    platform: response.data.platform,
                    image: null,
                });
            } catch (error) {
                toast.error('Error fetching banner: ' + (error.response?.data?.message || error.message)); 
                console.error('Error fetching banner:', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchBanner();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const textFields = {
            description: formData.description,
            product_link: formData.product_link,
            platform: formData.platform,
        };

        try {
            await axios.put(`${BASE_URL}/banners/${id}`, textFields, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            if (formData.image) {
                const imageData = new FormData();
                imageData.append('image', formData.image);

                await axios.post(`${BASE_URL}/banners/${id}/upload-image`, imageData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
            }

            toast.success('Banner updated successfully!'); 
            navigate('/dashboard/banners');
        } catch (error) {
            toast.error('Error updating banner: ' + (error.response?.data?.message || error.message)); 
            console.error('Error updating banner:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    if (loading) {
        return (
            <div className="container mt-5 d-flex justify-content-center">
                <Loader /> 
            </div>
        );
    }

    if (!banner) return <div>Loading...</div>;

    return (
        <div className="container-fluid">
            <div className="row">
               
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="container-fluid  mt-4 p-5 bg-light rounded shadow-sm">
                        <h1 className="mb-4">Edit Banner</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="product_link" className="form-label">Product Link</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="product_link"
                                    name="product_link"
                                    value={formData.product_link}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="platform" className="form-label">Platform</label>
                                <select
                                    className="form-select"
                                    id="platform"
                                    name="platform"
                                    value={formData.platform}
                                    onChange={handleChange}
                                >
                                    <option value="desktop">Desktop</option>
                                    <option value="mobile">Mobile</option>
                                    <option value="mobilead">Mobile Ads</option>
                                    <option value="both">Both</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    name="image"
                                    onChange={handleChange}
                                />
                                {banner.image && (
                                    <img
                                        src={banner.image}
                                        alt="Banner"
                                       style={{ width: '120px', height: '70px', marginTop: '10px', borderRadius: '10px' }}
                                        className="mt-2"
                                    />
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Update Banner
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default EditBanner;
