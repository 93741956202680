import React, { useEffect, useRef, useCallback, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CartComponent } from './Components/CartComponent';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../../../Services/UserContext.js';
import { BASE_URL } from '../../../Services/index.js';
import Header from '../../Components/HeaderComponent';
import Loader from '../../Components/LoaderComponent';
import Footer from '../../Components/FooterCompnent';
import 'react-toastify/dist/ReactToastify.css';

export default function CartScreen() {
    const cartItemsRef = useRef([]);
    const totalPriceRef = useRef(0);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoApplied, setPromoApplied] = useState(false);
    const [promoLoading, setPromoLoading] = useState(false);
    const navigate = useNavigate();
    const { userData, getCart, updateCart, removeFromCart } = useUser();
    const calculateTotalPrice = useCallback((items) => {
        return items.reduce((sum, item) => sum + item.quantity * item.price, 0);
    }, []);

    const updateTotalPrice = useCallback((newTotal) => {
        const gstAmount = newTotal * 0.16;
        const totalWithGst = newTotal + gstAmount;
        totalPriceRef.current = totalWithGst;

        document.getElementById('total').textContent = `RS. ${totalWithGst.toFixed(2)}`;
        document.getElementById('subtotal').textContent = `RS. ${newTotal.toFixed(2)}`;
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    const fetchCart = useCallback(async () => {
        if (!userData?.token) {
            toast.error('Please login to view cart');
            return;
        }
        setLoading(false);
        try {
            const data = await getCart(userData.token);
            if (data?.cart_items && Array.isArray(data.cart_items)) {
                cartItemsRef.current = data.cart_items;
                updateTotalPrice(calculateTotalPrice(data.cart_items));
            } else {
                cartItemsRef.current = [];
                updateTotalPrice(0);
            }
        } catch (error) {
            console.error('Error fetching cart:', error);
        } finally {
            setLoading(false);
            forceUpdate();
        }
    }, [userData, getCart, updateTotalPrice, calculateTotalPrice]);

    useEffect(() => {
        fetchCart();
    }, [fetchCart]);

    const handleQuantityChange = useCallback(async (item, newQuantity) => {
        try {
            const response = await updateCart({ id: item.item_id, quantity: newQuantity });
            if (response.status === 200) {
                const itemIndex = cartItemsRef.current.findIndex(cartItem => cartItem.item_id === item.item_id);
                if (itemIndex !== -1) {
                    cartItemsRef.current[itemIndex].quantity = newQuantity;
                    updateTotalPrice(calculateTotalPrice(cartItemsRef.current));
                    const itemElement = document.getElementById(`cart-item-${item.item_id}`);
                    if (itemElement) {
                        itemElement.querySelector('.quantity').textContent = newQuantity;
                    }
                }
            } else {
                toast.error(response.message || 'Failed to update cart');
            }
        } catch (error) {
            console.error('Error updating cart:', error);
            toast.error('Failed to update cart');
        }
    }, [updateCart, updateTotalPrice, calculateTotalPrice]);

    const handleDeleteItem = useCallback(async (item) => {
        try {
            const response = await removeFromCart({ id: item.item_id });
            if (response.status === 200) {
                const updatedCartItems = cartItemsRef.current.filter(cartItem => cartItem.item_id !== item.item_id);
                cartItemsRef.current = updatedCartItems; 
                updateTotalPrice(calculateTotalPrice(updatedCartItems));
                forceUpdate();
            } else {
                toast.error(response.message || 'Failed to remove item from cart');
            }
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    }, [removeFromCart, updateTotalPrice, calculateTotalPrice]);
    
    const handleCheckout = useCallback(() => {
        if (!userData?.token) {
            toast.error('Please login to checkout');
        } else if (cartItemsRef.current.length <= 0) {
            toast.error('Please add items to checkout');
        } else if (promoLoading) {  
            setPromoLoading(true);
        } else {
            navigate('/initialGameScreen', { state: { cartItems: cartItemsRef.current, totalWithGst: totalPriceRef.current } });
        }
    }, [userData, navigate, promoLoading]);

    const handlePromoBlur = () => {
        if (promoCode.trim()) {
            handleApplyPromoCode();
        }
    };
    const handleApplyPromoCode = async () => {
        if (!promoCode.trim()) {
            toast.error('Please enter a promo code');
            return;
        }
    
        setPromoLoading(true);
        setPromoApplied(false);
        try {
            const response = await axios.post(`${BASE_URL}/cart/apply-promo`, { promo_code: promoCode }, {
                headers: { Authorization: `Bearer ${userData?.token}` }
            });
    
            if (response.data.message === 'Promo code applied successfully') {
                toast.success('Promo code applied successfully');
                const newSubtotal = response.data.total_price;
                const gstAmount = newSubtotal * 0.16;
                const totalWithGst = newSubtotal + gstAmount;
                totalPriceRef.current = totalWithGst;
                const totalElement = document.getElementById('total');
                const subtotalElement = document.getElementById('subtotal');
                if (totalElement && subtotalElement) {
                    totalElement.textContent = `RS. ${totalWithGst.toFixed(2)}`;
                    subtotalElement.textContent = `RS. ${newSubtotal.toFixed(2)}`;
                }
                setPromoApplied(true);
                setPromoLoading(false);
                setPromoCode('');
                
            } else {
                toast.error(response.data.message || 'Failed to apply promo code');
            }
        } catch (error) {
            console.error('Error applying promo code:', error);
            toast.error(error.response?.data?.message || 'Failed to apply promo code');
        } finally {
            setPromoLoading(false);
        }
    };
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);

    return (
        <div>
            <Header />
            <ToastContainer />
            <section className="home-header inner-page">
                {loading ? (
                    <Loader />
                ) : (
                    <div className="row">
                        <div className="col-md-8 col-lg-9">
                            <div id="cart-container" className="sub-info glow" style={{ padding: 10, justifyContent: "center" }}>
                                {cartItemsRef.current.length <= 0 ? (
                                    <div style={{ justifyContent: "center", display: "flex", padding: 45 }}>
                                        <p>Your cart is empty</p>
                                    </div>
                                ) : (
                                    cartItemsRef.current.map(item => (
                                        <CartComponent
                                            key={item.item_id}
                                            item={item}
                                            increaseQuantity={() => handleQuantityChange(item, item.quantity + 1)}
                                            decreaseQuantity={() => handleQuantityChange(item, item.quantity - 1)}
                                            deleteItem={() => handleDeleteItem(item)}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="price-cart glow">
                                <div className="price-cart-wrapper">
                                    <div>
                                        <h4 className="price-heading text-left">Total</h4>
                                        <span className="inclusive">(Exclusive Of Tax)</span>
                                    </div>
                                    <div id="subtotal" className="value-price">RS. {calculateTotalPrice(cartItemsRef.current).toFixed(2)}</div>
                                </div>
                                <div>
                                
                                </div>
                                <div>
                                    <hr className="my-2 solid bg-dark" />
                                </div>
                                <div className="price-cart-wrapper text-left mt-3">
                                <div><h5 className=''>Subtotal </h5><span className="inclusive text-primary">(include 16% Tax)</span></div>
                                    <div id="total" className="value-price">RS. {totalPriceRef.current.toFixed(2)}</div>
                                </div>
                                
                                <div className="promo mt-3 d-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter promo code"
                                        value={promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        onBlur={handlePromoBlur} 
                                        style={{borderRadius: "10px 0px 0px 10px"}}
                                    />
                                    <button
                                        onClick={handleApplyPromoCode}
                                        className="btn btn-primary col-4 text-center"
                                        disabled={promoLoading}
                                        style={{borderRadius: "0px 10px 10px 0px"}}
                                    >
                                        {promoLoading ? 'Applying...' : 'Apply'}
                                        
                                    </button>
                                </div>
                                <button
                                    onClick={handleCheckout}
                                    className="btn btn-primary mt-3 w-100"
                                >
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </section>
            <Footer />
        </div>
    );
}
