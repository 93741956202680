
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';

const PromoCodeList = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/promo-codes`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        setPromoCodes(response.data);
      } catch (error) {
        console.error('Error fetching promo codes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromoCodes();
  }, [userData]);

  const handleEdit = (id) => {
    navigate(`/dashboard/edit-promo-codes/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/promo-codes/${id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      setPromoCodes(promoCodes.filter(promo => promo.id !== id));
      toast.success('Promo code deleted successfully!');
    } catch (error) {
      console.error('Error deleting promo code:', error);
      toast.error(`Error deleting promo code: ${error.response?.data?.message || error.message}`);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleAddClick = () => {
    navigate("/dashboard/create-promo-codes");
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const filteredPromoCodes = promoCodes.filter(promo =>
    promo.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
          <div className="dashboard-content mt-3">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Promo Codes</h1>
              <button className="btn btn-primary shadow-sm" onClick={handleAddClick}>
                <i className="bi bi-plus-circle me-2"></i>Add Promo Code
              </button>
            </div>

            <input
              type="text"
              placeholder="Search Promo Codes..."
              className="form-control mb-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                  <thead className="table-dark">
                    <tr>
                      <th>Promo Code</th>
                      <th>Description</th>
                      <th className="col-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPromoCodes.length ? (
                      filteredPromoCodes.map(promo => (
                        <tr key={promo.id}>
                          <td className='text-truncate align-middle' style={{ maxWidth: '300px' }}>{promo.code}</td>
                          <td className='text-truncate align-middle' style={{ maxWidth: '300px' }}>{promo.description}</td>
                      
                      
                          <td className="text-center align-middle">
                            <div className="d-flex justify-content-center gap-2">
                              <button className="btn btn-primary btn-sm" onClick={() => handleEdit(promo.id)}>
                                <i className="bi bi-pencil"></i>
                              </button>
                              <button className="btn btn-danger btn-sm" onClick={() => {
                                setItemToDelete(promo.id);
                                setShowDeleteModal(true);
                              }}>
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2" className="text-center">No Promo Codes available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this promo code?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={() => handleDelete(itemToDelete)}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default PromoCodeList;
