import React, { useState, useEffect } from 'react';
import './Styless.css';
import Loader from './LoaderComponent';
import { BASE_URL } from '../../Services';

const PromoCodeModal = () => {
    const [user, setUser] = useState(null);
    const [promoCode, setPromoCode] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [buttonText, setButtonText] = useState("Copy Code");
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = () => setShowModal(false);

    useEffect(() => {
        const loadUserDataAndPromoCode = async () => {
            try {
                const storedUserData = localStorage.getItem('userData');

                if (storedUserData) {
                    const parsedUserData = JSON.parse(storedUserData);
                    setUser(parsedUserData);

                    const response = await fetch(`${BASE_URL}/promo-codes`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch promo code');
                    }
                    
                    const promoCodeData = await response.json();
                    const PromoCodes = promoCodeData[promoCodeData.length - 1];
                    setPromoCode(PromoCodes ? PromoCodes.code : null);

                    if (parsedUserData.promo_codes === null) {
                        setTimeout(() => {
                            setShowModal(true);
                            setIsLoading(false);
                        }, 1000);
                    } else {
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Failed to load user data or promo code:', error);
                setIsLoading(false);
            }
        };
        loadUserDataAndPromoCode();
    }, []);

    const handleCopyCode = () => {
        if (promoCode) {
            navigator.clipboard.writeText(promoCode);
            setButtonText("COPIED");
            
            setTimeout(() => {
                setButtonText("Copy Code");
                setShowModal(false);
            }, 1000);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        showModal && (
            <div className="modal-overlay">
                <div className="coupon-card position-relative ">
                    <button 
                        type="button" 
                        className="close-btn" 
                        onClick={handleClose}
                    >
                        ×
                    </button>
                    <div className="text-center ">
                        <img 
                            src={require("../images/logo.png")}
                            alt="Enaam Logo" 
                            className="logo mb-3"
                        />
                        <h3 className='text-center text-white'>Thank you for registering, {user?.name}!</h3>
                        <h4 className='text-center text-white mt-3'>Get 100% off on your 1st ticket</h4>
                        <div className="coupon-row mt-3">
                            <span id="cpnCode">{promoCode || 'N/A'}</span>
                            <button id="cpnBtn" onClick={handleCopyCode}>
                                {buttonText}
                            </button>
                        </div>
                        <p className='text-center text-white'>Valid Till: 20 Dec, 2024</p>
                    </div>
                </div>
            </div>
        )
    );
};

export default PromoCodeModal;
