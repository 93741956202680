
 
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/HomeScreen/index';
import Prizes from './pages/PrizesScreen';
import Winners from './pages/WinnersPage';
import AboutUs from './pages/AboutUsScreen';

import Blogs from './pages/BlogsScreen';

import TermsConditions from './pages/Terms&ConditionsScreen';
import FAQs from './pages/FAQsScreen';

import BlogDetails from './pages/BlogsScreen/BlogDetails';
import Contact from './pages/ContactsUsScreen/Components/Contact';
import CartScreen from './pages/CartScreen';
import InititalGameScreen from './pages/GameScreen';
import GameScreen from './pages/GameScreen/GameScreen';
import ProfileScreen from '../Dashboard/ProfileScreen';
import ProductDetails from './pages/PrizesScreen/ProductDetails';
import RecentLuckyDraws from './pages/RecentLuckyDrawsScreen';
import PrivacyPolicy from './pages/PrivacyPolicy';
import YouTubeChannelVideos from './pages/HomeScreen/Components/YouTubeChannelVideos';


const UserSide = () => {
  return (
 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/youtube-videos" element={<YouTubeChannelVideos />} />
 
        <Route path="/prizes" element={<Prizes />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/recentLuckyDraws" element={<RecentLuckyDraws />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contactUs" element={<Contact />} />
        
        <Route path="/terms&Conditions" element={<TermsConditions />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/cart" element={<CartScreen />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/initialGameScreen" element={<InititalGameScreen />} />
        <Route path="/profileScreen" element={<ProfileScreen />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        

        <Route path="/GameScreen" element={<GameScreen />} />
      </Routes>
 
  );
};

export default UserSide;
