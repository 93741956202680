import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Services/AuthContext';
import './UserEdit.css';

function Login() {
    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);


   
   
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login({ email_or_phone: emailOrPhone, password });
    
            if (response?.status === 200) {
                if (response?.user?.role === 'admin') {
                    navigate('/dashboard');
                } else {
                    navigate('/');
                }
                setMessage('Login successful.');
                setError('');
                window.location.reload();
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setError('The user with that email or phone does not exist.');
            } else if (error.response?.status === 401) {
                setError('Incorrect password. Please try again.');
            } else {
                setError('Login failed due to an unexpected error. Please try again.');
            }
            setMessage('');
        }
    };
    return (
        <div className="d-flex justify-content-center align-items-center" >
            <div className=" p-1" style={{ borderRadius: '15px', width: '400px' }}>
                <div className="text-center mb-4">
                    <div className="mb-3">
                        <img
                            src={require('../UserSide/images/logo.png')}
                            alt="Login Icon"
                            style={{ width: '120px', height: '50px' }}
                        />
                    </div>
                    <h3 className="mb-3" style={{ color: '#35B5FF' }}>Login Now</h3>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <input
                            type="text"
                            id="emailOrPhone"
                            className="form-control form-control-text"
                            placeholder="Enter your Email or Phone#"
                            onChange={(e) => setEmailOrPhone(e.target.value)}
                            required
                            style={{
                                borderRadius: '20px',
                                borderColor: '#E6E6E6',
                                padding: '10px',
                                
                            }}
                        />
                    </div>

                    <div className="mb-3 position-relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control  form-control-text"
                            placeholder="Enter your Password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{
                                borderRadius: '20px',
                                borderColor: '#E6E6E6',
                                padding: '10px',
                            }}
                        />
                        <i
                            className={`bi ${showPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5'} position-absolute top-50 end-0 translate-middle me-2 pt-1`}
                            style={{ cursor: 'pointer', }}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <div className="mb-3 form-check" style={{ justifyContent: "flex-start", display: "flex" }}>
                        <input type="checkbox" className="form-check-input" id="rememberMe" />
                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary w-100"
                        style={{
                            backgroundColor: '#35B5FF',
                            borderColor: '#35B5FF',
                            borderRadius: '20px',
                            padding: '10px',
                            fontSize: '16px',
                        }}
                    >
                        LOGIN
                    </button>
                </form>
                {message && <p className="text-success mt-3 text-center">{message}</p>}
                {error && <p className="text-danger mt-3 text-center">{error}</p>}
                <div className="d-flex justify-content-between mt-4">
                    <Link to="/dashboard/forgot-password" style={{ fontSize: '14px', color: "red", fontWeight: "bold" }}>
                        Forgot password?
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;
