import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';

function PromoCodeEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPromoCode = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/promo-codes/${id}`);
        setCode(response.data.code);
        setDescription(response.data.description);
      } catch (error) {
        console.error('Error fetching promo code:', error);
        toast.error('Failed to fetch promo code.');
      } finally {
        setLoading(false);
      }
    };

    fetchPromoCode();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target; 
    if (name === 'code') {
      setCode(value); 
    } else if (name === 'description') {
      setDescription(value); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(`${BASE_URL}/promo-codes/${id}`, { code, description }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast.success('Promo code updated successfully!');
      navigate('/dashboard/promocodes');
    } catch (error) {
      console.error('Error updating promo code:', error);
      toast.error('Failed to update promo code.');
    } finally {
      setLoading(false);
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  if (loading) {
    return (
      <div className="container mt-5 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
          <div className="container-fluid mt-4 p-5 bg-light rounded shadow-sm">
            <h1 className="mb-4">Edit Promo Code</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="code" className="form-label">Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code" 
                  value={code}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  name="description" 
                  value={description}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Update Promo Code
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default PromoCodeEdit;
