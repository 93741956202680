import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent'; 

function RewardEdit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
    const [loading, setLoading] = useState(true);
    const { userData } = useUser;

    useEffect(() => {
        const fetchReward = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`${BASE_URL}/rewards/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });
                setName(response.data.name);
                setDescription(response.data.description);
                setCurrentImage(response.data.image);
            } catch (error) {
                console.error(error);
                toast.error(`Error fetching reward: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false); 
            }
        };

        fetchReward();
    }, [id]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const textData = {
            name,
            description,
        };
        const formData = new FormData();
        if (image) {
            formData.append('image', image);
        }

        try {
            await axios.put(`${BASE_URL}/rewards/${id}`, textData, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (image) {
                await axios.post(`${BASE_URL}/rewards/${id}/upload`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            toast.success('Reward updated successfully!');
            navigate('/dashboard/rewards'); 
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            if (error.response) {
                toast.error(`Error: ${error.response.data.message || 'Failed to update reward.'}`);
            } else if (error.request) {
                toast.error('Error: No response from the server.');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                   
                    <div className="container-fluid  col-12">
                        <div className="p-4 mt-4 bg-light rounded shadow-sm">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader /> 
                                </div>
                            ) : (
                                <div>
                                    <h1 className="mb-4 fs-4 fw-bold text-dark">Edit Reward</h1>
                                    <form onSubmit={handleUpdate}>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input 
                                                type="text" 
                                                id="name" 
                                                className="form-control" 
                                                value={name} 
                                                onChange={(e) => setName(e.target.value)} 
                                                required 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="currentImage" className="form-label">Current Image</label>
                                            {currentImage && (
                                                <div className="mb-3">
                                                    <img 

                                                        src={currentImage} 
                                                        alt="Current Reward" 
                                                        className=""
                                                        style={{ width: '200px', height: '160px', borderRadius: '10px' }} 
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="image" className="form-label">New Image</label>
                                            <input 
                                                type="file" 
                                                id="image" 
                                                className="form-control" 
                                                onChange={(e) => setImage(e.target.files[0])} 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea 
                                                id="description" 
                                                className="form-control" 
                                                value={description} 
                                                onChange={(e) => setDescription(e.target.value)} 
                                                required 
                                            ></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-primary shadow-sm">
                                            Update Reward
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default RewardEdit;
