import React, { createContext, useContext, useState, useEffect } from 'react';
import { BASE_URL } from '.';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUser] = useState(undefined);

  useEffect(() => {
      const loadUserData = async () => {
        try {
          const storedUserData = localStorage.getItem('userData');
          if (storedUserData) {
            setUser(JSON.parse(storedUserData));
          }
        } catch (error) {
          console.error('Failed to load user data:', error);
        }
      };
      loadUserData();
    }, []);

  useEffect(() => {
    const saveUserData = async () => {
      try {
        if (userData) {
          localStorage.setItem('userData', JSON.stringify(userData));
        } else {
          localStorage.removeItem('userData');
        }
      } catch (error) {
        console.error('Failed to save user data:', error);
      }
    };
    saveUserData();
  }, [userData]);

 
  const login = async (payload) => {
    try {
        const response = await axios.post(`${BASE_URL}/login`, payload);
        setUser(response?.data?.user);
        return response.data;
    } catch (error) {
        console.error('Error during sign in:', error);
        setUser(null);

       
        throw error;
    }
};

  const signUp = async (payload) => {
    try {
      const response = await axios.post(`${BASE_URL}/register`, payload);
      setUser(response.data?.user);
      return response.data;
    } catch (error) {
      console.error('Error during sign up:', error);
      setUser(null);
      return false;
    }
  };

  const logout = async () => {
    setUser(null);
    try {
      await localStorage.removeItem('userData');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userData,
        login,
        signUp,
        logout
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
