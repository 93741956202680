import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DashboardRoutes } from './Dashboard/index.js'
import UserSide from './UserSide/index.js';
import { UserProvider } from './Services/UserContext.js';
import { AuthProvider } from './Services/AuthContext.js';


function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <Router>
                    <UserSide />
                    <DashboardRoutes />
                </Router>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;