

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ParticipantsList = () => {
    const [participants, setParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [rewardFilterText, setRewardFilterText] = useState('');
    const [selectedReward, setSelectedReward] = useState('');
    const [answerFilterText, setAnswerFilterText] = useState('');
    const [usernameFilterText, setUsernameFilterText] = useState(''); // New state for username filter
    const { userData } = useUser();
    const [sortOption, setSortOption] = useState('asc');
    const convertTimeToSeconds = (timeString) => {
        const [minutes, seconds] = timeString?.split(':').map(Number);
        return (minutes * 60) + seconds;
    };

    const [uniqueRewards, setUniqueRewards] = useState([]);

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/participants`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });
                setParticipants(response.data);
                setFilteredParticipants(response.data);
                extractUniqueRewards(response.data);
            } catch (error) {
                console.error('Error fetching participants:', error);
                toast.error(`Error fetching participants: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchParticipants();
    }, []);

    const extractUniqueRewards = (participants) => {
        const rewardsSet = new Set();
        participants.forEach(participant => {
            const products = parseProducts(participant.processed_invoice?.products);
            products.forEach(product => {
                if (product.reward && product.reward.name) {
                    rewardsSet.add(product.reward.name);
                }
            });
        });
        setUniqueRewards(Array.from(rewardsSet));
    };


    useEffect(() => {
        const lowercasedRewardFilterText = rewardFilterText.toLowerCase();
        const lowercasedAnswerFilterText = answerFilterText.toLowerCase();
        const lowercasedUsernameFilterText = usernameFilterText.toLowerCase();

        const filtered = participants.filter(participant => {
            const username = participant.processed_invoice && participant.processed_invoice.user
                ? participant.processed_invoice.user.name.toLowerCase()
                : '';
            const hasRewardMatch = participant.processed_invoice && participant.processed_invoice.products && parseProducts(participant.processed_invoice.products).some(product =>
                product.reward && product.reward.name.toLowerCase().includes(lowercasedRewardFilterText)
            );

            const hasSelectedRewardMatch = selectedReward === '' || participant.processed_invoice && parseProducts(participant.processed_invoice.products).some(product =>
                product.reward && product.reward.name === selectedReward
            );

            const hasAnswerMatch = participant.processed_invoice ? participant.processed_invoice.is_correct.toLowerCase().includes(lowercasedAnswerFilterText) : false;
            const hasUsernameMatch = lowercasedUsernameFilterText === '' || username.includes(lowercasedUsernameFilterText);

            return (lowercasedRewardFilterText === '' || hasRewardMatch) && hasSelectedRewardMatch && (lowercasedAnswerFilterText === '' || hasAnswerMatch) && hasUsernameMatch;
        });

        // Sort the filtered participants by answer_time based on the selected sort option
        const sortedFilteredParticipants = filtered.sort((a, b) => {
            const answerTimeA = a.processed_invoice ? convertTimeToSeconds(a.processed_invoice.answer_time) : 0;
            const answerTimeB = b.processed_invoice ? convertTimeToSeconds(b.processed_invoice.answer_time) : 0;

            if (sortOption === 'asc') {
                return answerTimeA - answerTimeB; // Sort from least to highest
            } else {
                return answerTimeB - answerTimeA; // Sort from highest to least
            }
        });

        setFilteredParticipants(sortedFilteredParticipants);
    }, [rewardFilterText, selectedReward, answerFilterText, usernameFilterText, participants, sortOption]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const parseProducts = (products) => {
        if (typeof products === 'string') {
            try {
                const parsed = JSON.parse(products);
                return Array.isArray(parsed) ? parsed : [];
            } catch (error) {
                console.error('Error parsing products JSON:', error);
                return [];
            }
        }
        return Array.isArray(products) ? products : [];
    };


    const downloadPDF = () => {
        const pdf = new jsPDF();
        const pageHeight = pdf.internal.pageSize.height;

        // Add main heading
        pdf.setFontSize(20);
        pdf.text("Participant Tickets List", 10, 20);
        pdf.setFontSize(12);

        let position = 30; // Position to start content after the heading
        const allRewards = new Set(); // Set to collect unique rewards

        filteredParticipants.forEach((participant) => {
            // Check if processed_invoice exists before accessing its properties
            const processedInvoice = participant.processed_invoice || {}; // Use an empty object if undefined

            const userName = processedInvoice.user ? processedInvoice.user.name : 'N/A';
            const invoiceId = processedInvoice.invoice_id || 'N/A';
            const answer = processedInvoice.is_correct || 'N/A';
            const email = processedInvoice.user ? processedInvoice.user.email : 'N/A';
            const userNumber = participant.userNumber;
            const answerTime = processedInvoice.answer_time || 'N/A'; // Initialize answerTime here
            const tickets = processedInvoice.ticket_id || ['N/A'];

            const rewards = processedInvoice.products
                ? parseProducts(processedInvoice.products).map(product => product.reward ? product.reward.name : 'N/A')
                : ['N/A'];

            // Collect rewards into the set
            rewards.forEach(reward => allRewards.add(reward));

            // Create a mapping of ticket IDs to rewards
            const ticketRewards = {};
            let lastReward = 'No Reward'; // Initialize to a default value

            tickets.forEach((ticketID, index) => {
                if (index < rewards.length) {
                    lastReward = rewards[index]; // Update lastReward if a valid reward is available
                    ticketRewards[ticketID] = lastReward; // Assign the current reward
                } else {
                    ticketRewards[ticketID] = lastReward; // Repeat the last available reward for the remaining tickets
                }
            });

            tickets.forEach((ticketID) => {
                // Display Ticket ID with bold label
                pdf.setFont("helvetica", "bold");
                pdf.text(`Ticket ID: ${ticketID}`, 10, position + 10);
                pdf.setFont("helvetica", "normal");

                // User Name
                pdf.setFont("helvetica", "bold");
                pdf.text("User Name:", 10, position + 20);
                pdf.setFont("helvetica", "normal");
                pdf.text(userName, 50, position + 20);

                // Invoice ID
                pdf.setFont("helvetica", "bold");
                pdf.text("Invoice ID:", 130, position + 20);
                pdf.setFont("helvetica", "normal");
                pdf.text(invoiceId, 160, position + 20);

                // User Number
                pdf.setFont("helvetica", "bold");
                pdf.text("Number:", 10, position + 30);
                pdf.setFont("helvetica", "normal");
                pdf.text(userNumber, 50, position + 30);

                // Answer
                pdf.setFont("helvetica", "bold");
                pdf.text("Answer:", 130, position + 30);
                pdf.setFont("helvetica", "normal");
                pdf.text(answer, 160, position + 30);

                // Email
                pdf.setFont("helvetica", "bold");
                pdf.text("Email:", 10, position + 40);
                pdf.setFont("helvetica", "normal");
                pdf.text(email, 50, position + 40);

                // Answer Time (new addition)
                pdf.setFont("helvetica", "bold");
                pdf.text("Answer Time:", 130, position + 40);
                pdf.setFont("helvetica", "normal");
                pdf.text(answerTime, 160, position + 40); // Use answerTime here

                // Move position down to add the reward below Email and Answer Time
                position += 1; // Move to the next line

                // Associated Reward (specific for each ticket)
                const rewardForTicket = ticketRewards[ticketID] || 'No Reward'; // Default if no reward
                pdf.setFont("helvetica", "bold");
                pdf.text("Reward:", 10, position + 50);
                pdf.setFont("helvetica", "normal");
                pdf.text(rewardForTicket, 50, position + 50);

                // Divider line
                const dividerY = position + 60;
                pdf.line(10, dividerY, 200, dividerY);

                // Update position for the next entry
                position += 70;

                // Check if the next entry will fit the page, else add a new page
                if (position > pageHeight - 20) {
                    pdf.addPage();
                    pdf.text("", 10, 20);
                    pdf.setFontSize(12);
                    position = 30;
                }
            });
        });

        // Convert all rewards set to a string and create filename
        const rewardNames = Array.from(allRewards).join('-').replace(/:/g, '').replace(/\s+/g, '_'); // Format rewards for filename
        const filename = `Tickets-list(${rewardNames}).pdf`;

        pdf.save(filename);
    };



    if (loading) return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Participants List</h1>
                        </div>
                        <div className='d-flex justify-content-center mb-3'>
                            <input
                                type="text"
                                placeholder="Filter by Username"
                                className="form-control me-2"
                                value={usernameFilterText}
                                onChange={(e) => setUsernameFilterText(e.target.value)}
                            />



                            <select
                                className="form-control"
                                value={selectedReward}
                                onChange={(e) => setSelectedReward(e.target.value)}
                            >
                                <option value="">Select Reward</option>
                                {uniqueRewards.map((reward, index) => (
                                    <option key={index} value={reward}>{reward}</option>
                                ))}
                            </select>
                            <select
                                className="form-control ms-2"
                                value={answerFilterText}
                                onChange={(e) => setAnswerFilterText(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                            <select
                                className="form-control ms-2"
                                value={sortOption}
                                onChange={(e) => setSortOption(e.target.value)} >
                                <option value="desc">MIN TO MAX</option>
                                <option value="asc">MAX TO MIN</option>
                            </select>
                            <button className="btn btn-primary ms-2" onClick={downloadPDF}>PDF</button>
                        </div>

                        <div className="table-responsive">
                            {filteredParticipants.length > 0 ? (
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Sr</th>
                                            <th>Username</th>
                                            <th>Invoice ID</th>
                                            <th>Answer</th>
                                            <th>Amount</th>
                                            <th>Number</th>
                                            <th>Products & Rewards</th>
                                            <th>Ticket IDs</th>
                                            <th>Answer Time</th>
                                            <th className="col-1 text-center">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredParticipants.map(participant => (
                                            <tr key={participant.id}>
                                                <td className="align-middle">{filteredParticipants.indexOf(participant) + 1}</td>
                                                <td className="align-middle">{participant.processed_invoice && participant.processed_invoice.user ? participant.processed_invoice.user.name : 'N/A'}</td>
                                                <td className="align-middle">{participant.processed_invoice ? participant.processed_invoice.invoice_id : 'N/A'}</td>
                                                <td className="align-middle">{participant.processed_invoice ? participant.processed_invoice.is_correct : 'N/A'}</td>

                                                <td className="align-middle">{Number(participant.total_amount).toFixed(2)}</td>
                                                <td className="align-middle">{participant.userNumber}</td>
                                                <td className="align-middle">
                                                    {participant.processed_invoice && participant.processed_invoice.products ? (
                                                        parseProducts(participant.processed_invoice.products).map((product, index) => (
                                                            <div className='d-flex justify-content-between' key={index}>
                                                                {product.reward && (
                                                                    <div>
                                                                        <p className='text-dark'>{product.reward.name}</p>
                                                                    </div>
                                                                )}
                                                                <hr />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No products or invalid products data</p>
                                                    )}
                                                </td>
                                                <td className="align-middle">
                                                    {Array.isArray(participant.processed_invoice?.ticket_id)
                                                        ? participant.processed_invoice.ticket_id.join(', ')
                                                        : participant.processed_invoice?.ticket_id || 'N/A'}
                                                </td>

                                                <td className="align-middle">{participant.processed_invoice && participant.processed_invoice.answer_time !== null ? participant.processed_invoice.answer_time : 'N/A'}</td>

                                                <td className="align-middle text-center">
                                                    <Link to={`/dashboard/participants/${participant.id}`} className="btn btn-info btn-sm">

                                                        <i className="bi bi-eye"></i></Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='text-center fw-bold text-danger'>No participants found.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParticipantsList;
