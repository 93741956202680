import React from 'react';
import ReactDOM from 'react-dom';
import './CustomModal.css'; 

const CustomModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; 

    return ReactDOM.createPortal(
        <div className="custom-modal-overlay" onClick={onClose}>
            <div className="custom-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="custom-modal-close" onClick={onClose}>×</button>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default CustomModal;
