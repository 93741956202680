

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import { BASE_URL } from '../Services';

import Header from '../UserSide/Components/HeaderComponent';
import Footer from '../UserSide/Components/FooterCompnent';

function ForgotPassword() {
    const [input, setInput] = useState(''); // Change state to handle both email and phone
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState('request'); 
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); 

    const handleRequest = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/forgot-password`, { input });
            setMessage(response.data.message);
            setError('');
            setStep('verify');
        } catch (error) {
            setError('Failed to send OTP.');
            setMessage('');
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/verify-otp-reset`, { input, otp });
    
            if (response.data.status === 200) {
                setMessage(response.data.message);
                setError('');
    
                const userResponse = await axios.post(`${BASE_URL}/get-user-details`, { input });
    
                navigate('/reset-password', { state: { user: userResponse.data.user } });
            } else {
                setError('Invalid OTP.');
                setMessage('');
            }
        } catch (error) {
            setError('Invalid OTP.');
            setMessage('');
        }
    };

    return (
        <div className="App">
            <Header />
            <div className="container mt-5">
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6 col-lg-6 mt-5">
                        <div className="card p-4 shadow-lg" style={{ borderRadius: '10px' }}>
                            <div className="mb-3">
                                <img
                                    src={require('../UserSide/images/logo.png')}
                                    alt="Login Icon"
                                    style={{ width: '120px', height: '50px' }}
                                />
                            </div>
                            <h3 className="mb-4 text-center" style={{ color: '#35B5FF' }}>{step === 'request' ? 'Forgot Password' : 'Verify OTP'}</h3>
                            {step === 'request' ? (
                                <form onSubmit={handleRequest}>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            id="input"
                                            className="form-control"
                                            placeholder="Enter your email or phone number"
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                            required
                                            style={{
                                                borderRadius: '20px',
                                                borderColor: '#E6E6E6',
                                                padding: '10px',
                                            }}
                                        />
                                    </div>
                                    <button type="submit" 
                                            className="btn btn-primary w-100"
                                            style={{
                                                backgroundColor: '#35B5FF',
                                                borderColor: '#35B5FF',
                                                borderRadius: '20px',
                                                padding: '10px',
                                                fontSize: '16px',
                                            }}
                                    >Send OTP</button>
                                </form>
                            ) : (
                                <form onSubmit={handleVerifyOtp}>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            id="otp"
                                            className="form-control"
                                            placeholder="Enter OTP"
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            required
                                            style={{
                                                borderRadius: '20px',
                                                borderColor: '#E6E6E6',
                                                padding: '10px',
                                            }}
                                        />
                                    </div>
                                    <button type="submit" 
                                            className="btn btn-primary w-100"
                                            style={{
                                                backgroundColor: '#35B5FF',
                                                borderColor: '#35B5FF',
                                                borderRadius: '20px',
                                                padding: '10px',
                                                fontSize: '16px',
                                            }}
                                    >Verify OTP</button>
                                </form>
                            )}
                            {message && <p className="mt-3 text-success text-center">{message}</p>}
                            {error && <p className="mt-3 text-danger text-center">{error}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
