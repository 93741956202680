


import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import Header from './Header';
import { BASE_URL } from '../../Services';



const screenList = [
    { title: "Users", nav: "/dashboard", icon: "bi-house-door" },
    { title: "Promocodes", nav: "/dashboard/promocodes", icon: "bi bi-bar-chart" },
    { title: "Rewards", nav: "/dashboard/rewards", icon: "bi bi-trophy" },
    { title: "Products", nav: "/dashboard/products", icon: "bi bi-box" },
    { title: "Questions", nav: "/dashboard/questions", icon: "bi bi-question-circle" },
    { title: "FAQs", nav: "/dashboard/faqs", icon: "bi bi-question" },
    { title: "About Us", nav: "/dashboard/about-us", icon: "bi bi-info-circle" },
    { title: "Blogs", nav: "/dashboard/blogs", icon: "bi bi-journal-text" },
    { title: "Videos", nav: "/dashboard/videos", icon: "bi bi-film" },
    { title: "Banners", nav: "/dashboard/banners", icon: "bi bi-image" },
    { title: "Tickets", nav: "/dashboard/invoices", icon: "bi bi-receipt" },
    { title: "Participants", nav: "/dashboard/participants", icon: "bi bi-people" },
    { title: "Contacts", nav: "/dashboard/contacts", icon: "bi bi-telephone" },
    { title: "Winners", nav: "/dashboard/winners", icon: "bi bi-trophy-fill" },
    { title: "HangWinners", nav: "/dashboard/hang-winners", icon: "bi bi-gear" },
];

function Sidebar({ onToggleSidebar }) {
    const [isOpen, setIsOpen] = useState(true);
    const [selected, setSelected] = useState("");
    const [userRole, setUserRole] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = JSON.parse(localStorage.getItem('userData')); 
                if (!userData || !userData.token) {
                    console.error("User token not available");
                    return;
                }

                const userResponse = await axios.get(`${BASE_URL}/user/data`, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                });
                
                const user = userResponse.data?.user;
                if (user && user.role) {
                    console.log("Fetched User Role:", user.role);
                    setUserRole(user.role);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
        if (onToggleSidebar) {
            onToggleSidebar(!isOpen);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 750) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const currentItem = screenList.find(item => item.nav === location.pathname);
        if (currentItem) {
            setSelected(currentItem.title);
        }
    }, [location.pathname]);

    const filteredScreenList = screenList.filter(item => {
        if (userRole === 'editor' && (item.title === 'Users' || item.title === 'Tickets' || item.title === 'Participants'
            || item.title === 'Winners' || item.title === 'HangWinners' || item.title === 'Contacts' || item.title === 'Promocodes'
        )) {
            return false;
        }
        return true;
    });

    return (
        <div className="sidebar-container">
            <div style={{ flexDirection: "row" }}>
                <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                        <img
                            style={{ width: '150px', height: 'auto', margin: 10 }}
                            className="logo"
                            src={require('../../UserSide/images/logo.png')}
                            alt="Logo"
                        />
                        <button className="sidebar-toggle" onClick={toggleSidebar}>
                            {isOpen ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
                        </button>
                    </div>

                    {!isOpen ? (
                        <button className="sidebar-toggle-closed" onClick={toggleSidebar}>
                            {isOpen ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
                        </button>
                    ) : <div></div>}

                    <ul className="sidebar-menu">
                        {filteredScreenList.map((item, index) => (
                            <SideBarElement
                                key={index}
                                item={item}
                                selected={selected}
                                onClick={setSelected}
                            />
                        ))}
                    </ul>
                </div>
                <div>
                    <Header screenName={selected} />
                </div>
            </div>
        </div>
    );
}

export default Sidebar;

const SideBarElement = ({ item, selected, onClick }) => {
    return (
        <li onClick={() => onClick(item.title)}>
            <Link
                className={selected === item.title ? 'sidebar-selected' : 'sidebar-link'}
                to={item.nav}
            >
                <i className={item.icon}></i> {item.title}
            </Link>
        </li>
    );
};
