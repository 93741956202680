import React from 'react';

const truncateText = (text, charLimit) => {
    if (text.length > charLimit) {
        return text.slice(0, charLimit) + '..';
    }
    return text;
};

export const CartComponent = ({ item, increaseQuantity, decreaseQuantity, deleteItem }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    return (
<div className='container-fluid p-4 '>
<div style={{ borderRadius:'20px' }} className="row align-items-center mb-3 p-4 bg-white shadow mt-3"  key={item.item_id} data-product-id={item.item_id}>
            <div className="col-12 col-md-3 text-center mb-3 mb-md-0" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                <img
                    src={item.reward ? item.reward : item.image}
                    alt={item.name || 'Product Image'}
                    style={{ maxwidth: '100% !important', objectFit: 'cover', borderRadius: '20px' }}
                    className="img-fluid"
                />
            </div>


            <div className="col-12 col-md-5 text-lg-start text-md-center mb-2 mb-md-0 ">
               <h4 className="mb-0 mb-2 text-md-center text-lg-left text-sm-center fw-bold" style={{color: '#3789FF'}}>{item.name || 'No name available'}</h4>
                <p style={{fontSize: '16px'}} className="mb-2 d-none text-lg-left d-md-block"> {truncateText(item.description, 122) || 'No description available'}</p>
            </div>

            <p style={{fontSize: '14px'}} className="mb-3 d-md-none d-lg-none"> {truncateText(item.description, 122) || 'No description available'}</p>

            <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">
                <button 
                    type="button" 
                    className="btn btn-outline-secondary m-1 me-1" 
                    onClick={decreaseQuantity} 
                    disabled={item.quantity <= 1}
                >
                    <i className="bi bi-dash "></i>
                </button>

                <input 
                    type="text" 
                    id="txtAcrescimo" 
                    value={item.quantity} 
                    readOnly 
                    className="form-control d-inline-block text-center mx-2"
                    style={{ maxWidth: '40px',height:'40px',borderRadius:'10px', fontSize: '18px'}}
                />

                <button 
                    type="button" 
                    className="btn btn-outline-secondary m-1  me-2" 
                    onClick={increaseQuantity}
                >
                    <i className="bi bi-plus"></i>
                </button>

                <button 
                    type="button" 
                    className="btn btn-danger m-1 ms-2" 
                    onClick={() => {
                        setShowDeleteModal(true);
                    }}
                >
                    <i className="bi bi-trash"></i>
                </button>
            </div>
            {showDeleteModal && (
                            <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Confirm Deletion</h5>
                                            <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                                        </div>
                                        <div className="modal-body ">
                                            <p className='mb-0'>Are you sure you want to delete this item?</p>
                                        </div>
                                        <div className="modal-footer ">
                                            <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                                                Cancel
                                            </button>
                                            <button type="button" className="btn btn-danger" onClick={deleteItem}>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
        </div>


        </div>
    );
};
