


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../../Components/LoaderComponent';
import './RecentLuckyDraws.css';
import {YOU_TUBE_API } from '../../../../Services';

const RecentLuckyDraws = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [videosPerRow, setVideosPerRow] = useState(3);




    useEffect(() => {
        fetchVideosFromPlaylist()
    }, []);


    const fetchVideosFromPlaylist = async () => {
        try {
            const response = await axios.get(YOU_TUBE_API);
            const data = response?.data;
            const latestData = data?.items?.reverse();
            setVideos(latestData);
        } catch (error) {
            alert(error)
            console.error(error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVideosPerRow(1);
            } else {
                setVideosPerRow(3);
            }
        };


        handleResize();


        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading) return <Loader />;

    const handleThumbnailClick = (index) => {
        setActiveIndex(index);
    };

    const groupItems = (items, groupSize) => {
        const result = [];
        for (let i = 0; i < items.length; i += groupSize) {
            result.push(items.slice(i, i + groupSize));
        }
        return result;
    };

    const videoGroups = groupItems(videos, videosPerRow);

    return (
        <section className="image-with-content py-5 video-section ">
            <div className="container-fluid col-lg-10 col-md-11 col-11 recent-lucky-draw-section ">
                <div className="row align-items-center video-bg hide-on-mobile ">
                    <div className="col-lg-12 content-sec-1">
                    <h3 className="title-style mb-3" style={{ fontWeight: "bold", lineHeight: "0" }}>Recent Giveaways</h3>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="video-container p-3 transparent-background">
                            {videos?.length > 0 && <div className="video-embed p-2 ">

                                <iframe
                                    className='youtube-video'
                                    style={{ borderRadius: '20px', }}
                                    src={`https://www.youtube.com/embed/${videos[0]?.snippet?.resourceId?.videoId}`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>}

                            <div className="col-lg-12 mt-3">
                                <div id="thumbnailCarousel" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {videoGroups.map((group, index) => (
                                            <div
                                                key={index}
                                                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                            >
                                                <div className="row d-flex justify-content-center">
                                                    {group.map((video, subIndex) => (
                                                        <div key={subIndex} className="col-md-4 col-12 ">
                                                            <iframe
                                                                className='youtube-thumbnail'
                                                                style={{ borderRadius: '20px', width:"100%" }}
                                                                src={`https://www.youtube.com/embed/${video?.snippet?.resourceId?.videoId}`}
                                                                title="YouTube video player"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen
                                                            ></iframe>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <a
                                        style={{ marginLeft: '-40px' }}
                                        className="carousel-control-prev"
                                        href="#thumbnailCarousel"
                                        role="button"
                                        data-slide="prev"
                                    >
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a
                                        style={{ marginRight: '-40px' }}
                                        className="carousel-control-next"
                                        href="#thumbnailCarousel"
                                        role="button"
                                        data-slide="next"
                                    >
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RecentLuckyDraws;
