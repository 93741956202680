import React from 'react'

export const WinnerComponent = ({winner,data,index}) => {
  return (
    <div  key={index} className="mb-3 col-lg-4 mt-1 col-md-4 p-0 p-md-2 p-lg-2">
    <div className='glow mb-3 glow-card' style={{backgroundColor:"white",borderRadius:"15px",height:"435px"}}>
      <img className="img-fluid mt-3" style={{borderRadius:"15px", height:"230px", width:"100%"}} src={winner?.image} alt=" " />
      <h4 ><a  href="" className="title-head ">Congratulations</a></h4>
      <h5 className="title-text" style={{ color: 'black' ,fontSize:"20px"}}>{winner?.participant?.processed_invoice?.user?.name}</h5>
      <p style={{display:"block"}}>on Winning <b style={{ color: 'black' }}>{winner?.reward?.name}</b></p>
      {/* <small>Ticket no: {winner?.participant?.processed_invoice?.ticket_id}</small>  */}
      <small>Announced on {data}</small>
    </div>
  </div>
  )
}
