    import React, { useState } from 'react';
    import { useAuth } from '../Services/AuthContext';
    import { BASE_URL } from '../Services';
    
    import axios from 'axios';

    function Register() {
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [passwordConfirmation, setPasswordConfirmation] = useState('');   
        const [phone, setPhone] = useState('');
        const [otp, setOtp] = useState('');
        const [message, setMessage] = useState('');
        const [error, setError] = useState('');
        const [step, setStep] = useState(1);
        const [showPassword, setShowPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);

        const { signUp } = useAuth();

        const handleRegister = async (e) => {
            e.preventDefault();
        
            if (password.length < 8) {
                setError('Password must be at least 8 characters long.');
                return;
            }
        
            if (phone.length != 11) {
                setError('Phone number must be at least 11 digits long.');
                return;
            }
        
            if (password !== passwordConfirmation) {
                setError('Passwords do not match.');
                return;
            }
        
            try {
                const response = await axios.post(`${BASE_URL}/register`, {
                    name,
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    phone
                });
        
                if (response.data.status === 200) {
                    setMessage(response.data.message);
                    setError('');
                    setStep(2);
                } else {
                    setError(response.data.message);
                    setMessage('');
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    setError(error.response.data.message);
                } else {
                    setError('Registration failed. Please check your input and try again.');
                }
                setMessage('');
                console.error(error);
            }
        };
        

        const handleVerifyOtp = async (e) => {
            e.preventDefault();
            try {
                const response = await axios.post(`${BASE_URL}/verify-otp`, {
                    phone,
                    otp
                });
                localStorage.setItem('token', response.data.token);
                
                setMessage('Registration successful!');
                setError('');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                setError('OTP verification failed. Please try again.');
                setMessage('');
                console.error(error);
            }
        };

        return (
            <div className="d-flex justify-content-center align-items-center ">
                {step === 1 && (
                    <div className=" p-1" style={{  width: '400px' }}>
                        <div className="text-center mb-4">
                            <div className="mb-3">
                                <img
                                    src={require('../UserSide/images/logo.png')}
                                    alt="Register Icon"
                                    style={{ width: '120px' , height: '50px'}}
                                />
                            </div>
                            <h3 className="mb-3" style={{ color: '#35B5FF' }}>Register Now</h3>
                        </div>
                        <form onSubmit={handleRegister}>
                            <div className="mb-3 text-end pb-0">
                                <input
                                    type="text"
                                    id="name"
                                    className="form-control form-control-text"
                                    placeholder="Enter your Name *"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    style={{
                                        borderRadius: '20px',
                                        borderColor: '#E6E6E6',
                                        padding: '10px',
                                    }}
                                />
                            </div>
                            <div className="mb-3 text-end">
                                <input
                                    type="text"
                                    id="phone"
                                    className="form-control form-control-text"
                                    placeholder="Enter your Phone Number *"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                    style={{
                                        borderRadius: '20px',
                                        borderColor: '#E6E6E6',
                                        padding: '10px',
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    id="email"
                                    className="form-control form-control-text"
                                    placeholder="Enter your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{
                                        borderRadius: '20px',
                                        borderColor: '#E6E6E6',
                                        padding: '10px',
                                    }}
                                />
                            </div>
                                <div className="mb-3 position-relative text-end">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        className="form-control form-control-text"
                                        placeholder="Enter your Password *"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        style={{
                                            borderRadius: '20px',
                                            borderColor: '#E6E6E6',
                                            padding: '10px',
                                        }}

                                        
                                    />
                                    <i
                                        className={`bi ${showPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5 '} `}
                                        style={{ cursor: 'pointer',position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                                        
                             <div className="mb-3 position-relative">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                id="passwordConfirmation"
                                className="form-control form-control-text"
                                placeholder="Confirm your Password *"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                            <i
                                className={`bi ${showConfirmPassword ? 'bi-eye-slash fs-5' : 'bi-eye fs-5 '} `}
                                style={{ cursor: 'pointer', position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            />
                        </div>
                         
                            <button
                                type="submit"
                                className="btn btn-primary w-100"
                                style={{
                                    backgroundColor: '#35B5FF',
                                    borderColor: '#35B5FF',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    fontSize: '16px',
                                }}
                            >
                                Register
                            </button>
                        </form>
                        {message && <p className="text-success mt-3 text-center">{message}</p>}
                        {error && <p className="text-danger mt-3 text-center">{error}</p>}
                    </div>
                )}
                {step === 2 && (
                    <div className=" p-5" style={{ borderRadius: '15px', width: '400px' }}>
                        <div className="text-center mb-4">
                            <div className="mb-3">
                                <img
                                    src={require('../UserSide/images/logo.png')}
                                    alt="OTP Icon"
                                    style={{ width: '120px' , height: '50px'}}
                                />
                            </div>
                            <h3 className="mb-3" style={{ color: '#35B5FF' }}>Verify OTP</h3>
                        </div>
                        <form onSubmit={handleVerifyOtp}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    id="otp"
                                    className="form-control form-control-text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    required
                                    style={{
                                        borderRadius: '20px',
                                        borderColor: '#E6E6E6',
                                        padding: '10px',
                                    }}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary w-100"
                                style={{
                                    backgroundColor: '#35B5FF',
                                    borderColor: '#35B5FF',
                                    borderRadius: '20px',
                                    padding: '10px',
                                    fontSize: '16px',
                                }}
                            >
                                Verify OTP
                            </button>
                        </form>
                        {message && <p className="text-success mt-3 text-center">{message}</p>}
                        {error && <p className="text-danger mt-3 text-center">{error}</p>}
                    </div>
                )}
            </div>
        );
    }

    export default Register;
