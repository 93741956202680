import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from '../sidebar/Sidebar'; 
import Loader from '../../UserSide/Components/LoaderComponent'; 


function EditAboutUs() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [aboutUs, setAboutUs] = useState(null);
    const [formData, setFormData] = useState({
        heading: '',
        about_detail: '',
        about_image: null,
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const { userData } = useUser();

    useEffect(() => {
        const fetchAboutUs = async () => {
            setLoading(true); 
            try {
                const response = await axios.get(`${BASE_URL}/about-us/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                setAboutUs(response.data);
                setFormData({
                    heading: response.data.heading,
                    about_detail: response.data.about_detail,
                    about_image: null,
                });
            } catch (error) {
                console.error('Error fetching About Us data:', error);
                toast.error('Failed to fetch About Us data.');
            } finally {
                setLoading(false); 
            }
        };

        fetchAboutUs();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 

        try {
            await axios.put(`${BASE_URL}/about-us/${id}`, {
                heading: formData.heading,
                about_detail: formData.about_detail,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            if (formData.about_image) {
                const fileFormData = new FormData();
                fileFormData.append('about_image', formData.about_image);

                await axios.post(`${BASE_URL}/about-us/${id}/upload-image`, fileFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
            }

            toast.success('About Us updated successfully!');
            navigate('/dashboard/about-us');
        } catch (error) {
            console.error('Error updating About Us data:', error);
            toast.error('Failed to update About Us data.');
        } finally {
            setLoading(false); 
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    if (loading) {
        return (
            <div className="container mt-5 d-flex justify-content-center">
                <Loader />
            </div>
        );
    }

    if (!aboutUs) return <div>Loading...</div>;

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>

                    <div className="container-fluid  mt-4 p-5 bg-light rounded shadow-sm ">
                        <h1 className="mb-4">Edit About Us</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="heading" className="form-label">Heading</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="heading"
                                    name="heading"
                                    value={formData.heading}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="about_detail" className="form-label">About Detail</label>
                                <textarea
                                    className="form-control"
                                    id="about_detail"
                                    name="about_detail"
                                    value={formData.about_detail}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="about_image" className="form-label">About Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="about_image"
                                    name="about_image"
                                    onChange={handleChange}
                                />
                                {aboutUs.about_image && (
                                    <img
                                        style={{ borderRadius: '10px' }}
                                        src={aboutUs.about_image}
                                        alt="About Us"
                                        width="100"
                                        className="mt-2"
                                    />
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary bi ">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default EditAboutUs;
