
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../../Services/UserContext.js';
import { BASE_URL } from '../../Services/index.js';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const InvoicePage = () => {
    const [processed_invoices, setProcessedInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [answerFilter, setAnswerFilter] = useState(''); 
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const { userData } = useUser();

    useEffect(() => {
        const fetchProcessedInvoices = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/invoices`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                setProcessedInvoices(response.data.processed_invoices);
                setFilteredInvoices(response.data.processed_invoices);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching processed invoices:', error);
                setLoading(false);
            }
        };
        fetchProcessedInvoices();
    }, [userData]);

    useEffect(() => {
        const filtered = processed_invoices.filter(invoice => {
            const ticket_id = invoice.ticket_id ? invoice.ticket_id.toString().toLowerCase() : '';
            const user_name = invoice.user?.name.toLowerCase() || '';
            const user_email = invoice.user?.email.toLowerCase() || '';
            const user_phone = invoice.user?.phone || '';
            const answerMatch = answerFilter === '' || invoice.is_correct === answerFilter;

            return (
                (user_name.includes(searchTerm.toLowerCase()) ||
                user_email.includes(searchTerm.toLowerCase()) ||
                user_phone.includes(searchTerm) ||
                ticket_id.includes(searchTerm.toLowerCase())) &&
                answerMatch
            );
        });
        setFilteredInvoices(filtered);
    }, [searchTerm, answerFilter, processed_invoices]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

   
   
  

    const generatePDF = () => {
        const pdf = new jsPDF();
        const pageHeight = pdf.internal.pageSize.height;
    
        // Add heading at the top
        pdf.setFontSize(20); // Set font size for the heading
        pdf.text("Ticket Details", 10, 20); // Add heading text
        pdf.setFontSize(12); // Reset font size for the rest of the content
    
        let position = 30; // Start below the heading
    
        filteredInvoices.forEach((invoice) => {
            const name = invoice.user ? invoice.user.name : 'N/A';
            const email = invoice.user ? invoice.user.email : 'N/A';
            const phone = invoice.user ? invoice.user.phone : 'N/A';
            const date = new Date(invoice.created_at).toLocaleDateString();
            const answer = invoice.is_correct;
    
            if (invoice.ticket_id.length > 1) {
                invoice.ticket_id.forEach((ticketID) => {
                    // Set font to bold for Ticket ID
                    pdf.setFont("helvetica", "bold");
                    pdf.text(`Ticket ID: ${ticketID}`, 10, position + 10); 
                    pdf.setFont("helvetica", "normal"); // Reset to normal font
    
                    // Display Name and Email with bold headings
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Name:", 10, position + 20);
                    pdf.setFont("helvetica", "normal");
                    pdf.text(name, 40, position + 20); // Adjust position for name
                    
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Email:", 100, position + 20);
                    pdf.setFont("helvetica", "normal");
                    pdf.text(email, 140, position + 20); // Adjust position for email
    
                    // Display Phone and Quantity with bold headings
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Phone:", 10, position + 30);
                    pdf.setFont("helvetica", "normal");
                    pdf.text(phone, 40, position + 30); // Adjust position for phone
                    
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Quantity:", 100, position + 30);
                    pdf.setFont("helvetica", "normal");
                    pdf.text("1", 140, position + 30); // Adjust position for quantity
    
                    // Display Date with bold headings
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Date:", 10, position + 40);
                    pdf.setFont("helvetica", "normal");
                    pdf.text(date, 40, position + 40); // Adjust position for date
    
                    // Display Answer with a bold heading
                    pdf.setFont("helvetica", "bold");
                    pdf.text("Answer:", 100, position + 40);
                    pdf.setFont("helvetica", "normal");
                    pdf.text(answer, 140, position + 40); // Change Y-coordinate to align under Date
    
                    // Add divider
                    const dividerY = position + 60; // Position of the divider
                    pdf.line(10, dividerY, 200, dividerY); // Draw line from (10, dividerY) to (200, dividerY)
    
                    position += 80; 
    
                    if (position > pageHeight - 20) {
                        pdf.addPage();
                        pdf.text("Invoice Details", 10, 20); // Add heading again on the new page
                        pdf.setFontSize(12); // Reset font size for the rest of the content
                        position = 30; // Reset position below the heading
                    }
                });
            } else {
                const ticketID = invoice.ticket_id[0];
    
                // Set font to bold for Ticket ID
                pdf.setFont("helvetica", "bold");
                pdf.text(`Ticket ID: ${ticketID}`, 10, position + 10); 
                pdf.setFont("helvetica", "normal"); // Reset to normal font
    
                // Display Name and Email with bold headings
                pdf.setFont("helvetica", "bold");
                pdf.text("Name:", 10, position + 20);
                pdf.setFont("helvetica", "normal");
                pdf.text(name, 40, position + 20); // Adjust position for name
                
                pdf.setFont("helvetica", "bold");
                pdf.text("Email:", 100, position + 20);
                pdf.setFont("helvetica", "normal");
                pdf.text(email, 140, position + 20); // Adjust position for email
    
                // Display Phone and Quantity with bold headings
                pdf.setFont("helvetica", "bold");
                pdf.text("Phone:", 10, position + 30);
                pdf.setFont("helvetica", "normal");
                pdf.text(phone, 40, position + 30); // Adjust position for phone
                
                pdf.setFont("helvetica", "bold");
                pdf.text("Quantity:", 100, position + 30);
                pdf.setFont("helvetica", "normal");
                pdf.text("1", 140, position + 30); // Adjust position for quantity
    
                // Display Date with bold headings
                pdf.setFont("helvetica", "bold");
                pdf.text("Date:", 10, position + 40);
                pdf.setFont("helvetica", "normal");
                pdf.text(date, 40, position + 40); // Adjust position for date
    
                // Display Answer with a bold heading
                pdf.setFont("helvetica", "bold");
                pdf.text("Answer:", 100, position + 40);
                pdf.setFont("helvetica", "normal");
                pdf.text(answer, 140, position + 40); // Change Y-coordinate to align under Date
    
                // Add divider
                const dividerY = position + 60; // Position of the divider
                pdf.line(10, dividerY, 200, dividerY); // Draw line from (10, dividerY) to (200, dividerY)
    
                position += 80; 
    
                if (position > pageHeight - 20) {
                    pdf.addPage();
                    pdf.text("Invoice Details", 10, 20); // Add heading again on the new page
                    pdf.setFontSize(12); // Reset font size for the rest of the content
                    position = 30; // Reset position below the heading
                }
            }
        });
    
        pdf.save('tickets.pdf');
    };
    
    
    
    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Tickets</h1>
                        </div>
                        <div className='d-flex justify-content-center mb-3'>
                        <input
                            type="text"
                            placeholder="Search invoices..."
                            className="form-control col-5"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <select
                            className="form-control ms-2 col-5"
                            value={answerFilter}
                            onChange={(e) => setAnswerFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                         <button onClick={generatePDF} className="btn btn-primary ms-2 col-2">
                               Download PDF
                            </button>
                    </div>
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive mt-2">
                                <table id="invoice-table" className="table table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Sr</th>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th className="col-md-1 text-center">Quantity</th>
                                            <th className="col-md-1 text-center">Price</th>
                                            <th className="col-md-1 text-center">Date</th>
                                            <th className="col-md-1 text-center">Answer</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredInvoices.length === 0 ? (
                                            <tr>
                                                <td colSpan="10" className="text-center">No invoices found</td>
                                            </tr>
                                        ) : (
                                            filteredInvoices.map((invoice, index) => (
                                                <tr key={invoice.id}>
                                                    <td className="align-middle">{index + 1}</td>
                                                    <td className="align-middle">
                                                        {Array.isArray(invoice.ticket_id) 
                                                            ? invoice.ticket_id.join(',  ') 
                                                            : invoice.ticket_id || 'N/A'}
                                                    </td>
                                                    <td className="align-middle">{invoice.user ? invoice.user.name : 'N/A'}</td>
                                                    <td className="align-middle">{invoice.user ? invoice.user.email : 'N/A'}</td>
                                                    <td className="align-middle">{invoice.user ? invoice.user.phone : 'N/A'}</td>
                                                    <td className="text-center align-middle">{invoice.total_quantity}</td>
                                                    <td className="text-center align-middle">{Number(invoice.total_price).toFixed(2)}</td>
                                                    <td className="align-middle">{new Date(invoice.created_at).toLocaleDateString()}</td>
                                                    <td className="text-center align-middle">{invoice.is_correct}</td>
                                                    <td className="text-center align-middle">
                                                        <div className="d-flex gap-2 justify-content-center">
                                                            <Link to={`/dashboard/invoices/${invoice.id}`} className="btn btn-info btn-sm">
                                                                <i className="bi bi-eye"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default InvoicePage;
